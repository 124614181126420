import { createApp, markRaw } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia';
import { vMaska } from "maska";
import moment from 'moment';
import "vue-toastification/dist/index.css";
import "@/index.css";
import App from './App.vue';
import { useAuthStore } from "@/Stores/Auth.js";
import Toast from "vue-toastification";
import router from './Router';
import { useSettingStore } from "@/Stores/SettingStore.js";
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createI18n } from 'vue-i18n';

// Configuração da baseURL do axios
axios.defaults.baseURL = (window.location.protocol+ "//" + window.location.host); //prod
// axios.defaults.baseURL = "https://api1.sm1g00l.online"; //dev

function applyDynamicStyles(styles) {
    const root = document.documentElement;

    // Adicionar uma classe específica para aplicar os estilos
    root.classList.add('dynamic-styles');

    // Aplicar variáveis CSS com a classe dinâmica
    let cssVariables = '';
    for (const key in styles) {
        if (key.includes('_color') || key === 'font_family_default' || key === 'border_radius') {
            let cssKey = `--${key.replace(/_/g, '-')}`;
            cssVariables += `${cssKey}: ${styles[key]} !important;`;
        }
    }

    // Garantir que --ci-primary-color receba o valor de primary_color
    if (styles.primary_color) {
        cssVariables += `--ci-primary-color: ${styles.primary_color} !important;`;
        cssVariables += `--ci-secundary-color: ${styles.secundary_color} !important;`;
        cssVariables += `--ci-primary-opacity-color: ${styles.secundary_color} !important;`;
        cssVariables += `--text-color: ${styles.text_color} !important;`;
        cssVariables += `--title-color: ${styles.title_color} !important;`;
        cssVariables += `--background-color: ${styles.background_color} !important;`;
        cssVariables += `--background-base: ${styles.background_base} !important;`;
        cssVariables += `--sidebar-color: ${styles.sidebar_color} !important;`;
        cssVariables += `--sidebar-color-dark: ${styles.sidebar_color} !important;`;
        cssVariables += `--navtop-color: ${styles.navtop_color} !important;`;
        cssVariables += `--navtop-color-dark: ${styles.navtop_color} !important;`;
        cssVariables += `--footer-color: ${styles.footer_color} !important;`;
        cssVariables += `--footer-color-dark: ${styles.footer_color} !important;`;
        cssVariables += `--card-color-dark: ${styles.card_color} !important;`;
        cssVariables += `--card-color: ${styles.card_color} !important;`;
        cssVariables += `--input-primary: ${styles.input_primary} !important;`;
    }

    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    styleElement.innerHTML = `:root { ${cssVariables} }`;
    styleElement.innerHTML += `.dark :root { ${cssVariables} }`;

    // Adicionar o custom_css
    if (styles.custom_css) {
        styleElement.innerHTML += `.dynamic-styles { ${styles.custom_css.replace(/([^;\s}]+:[^;}]+)(;|})/g, "$1 !important$2")} }`;
    }

    document.head.appendChild(styleElement);
}

// Função para buscar estilos dinâmicos da API
async function fetchDynamicStyles() {
    try {
        const response = await axios.get("/api/settings/layout");
        if (response?.data) {
            applyDynamicStyles(response.data);
        } else {
            console.warn("Nenhum estilo encontrado na resposta da API:", response.data);
        }
    } catch (e) {
        console.error("Erro ao buscar estilos dinâmicos:", e);
    }
}

// Função para carregar os arquivos de tradução dinamicamente
const loadLocaleMessages = () => {
    const locales = import.meta.glob('./lang/*.json', { eager: true });
    const messages = {};
    for (const path in locales) {
        const matched = path.match(/\/lang\/(.*)\.json$/);
        if (matched && matched[1]) {
            messages[matched[1]] = locales[path];
        }
    }
    return messages;
};

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});

(async () => {
    await fetchDynamicStyles();

    const app = createApp(App);

    app.config.globalProperties.state = {
        platformName() {
            return 'VIPERPRO';
        },
        dateFormatServer(date) {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: '2-digit', minute: '2-digit' };
            return date.toLocaleDateString(document.documentElement.getAttribute("lang")) + 
                " às " + date.toLocaleTimeString(document.documentElement.getAttribute("lang"), options);
        },
        generateSlug(text) {
            return text
                .toString()
                .toLowerCase()
                .trim()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');
        },
        timeAgo(value) {
            return moment(value).fromNow();
        },
        currencyFormat(value, currency = 'USD') {
            const options = { style: 'currency', currency };
            return value.toLocaleString(document.documentElement.getAttribute("lang"), options);
        },
        currencyUSD(value) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            }).format(value);
        },
        limitCharacters(value, limite) {
            if (!value) return '';
            if (value.length <= limite) return value;
            return value.slice(0, limite) + '...';
        },
    };

    // Axios Integration
    app.use(VueAxios, axios);
    app.provide('axios', app.config.globalProperties.axios);

    // Plugins
    app.use(Toast);
    app.use(router);
    app.use(i18n);

    // Directive
    app.directive("maska", vMaska);

    // PINIA
    const pinia = createPinia();
    pinia.use(({ store }) => { store.router = markRaw(router); });
    app.use(pinia);

    // Initial Data Fetch
    const setting = useSettingStore();
    try {
        const settingData = await setting.getSetting();
        setting.setSetting(settingData);
    } catch (e) {
        console.error("Error fetching settings:", e);
    }

    if (localStorage.getItem('token')) {
        const auth = useAuthStore();
        try {
            auth.setIsAuth(true);
            const user = await auth.checkToken();
            if (user !== undefined) {
                auth.setUser(user);
            }
        } catch (e) {
            auth.setIsAuth(false);
        }
    }

    app.mount('#app');
})();
