<template>
    <div class="footer pb-32 md:pb-5 mt-5 footer-color p-4 md:p-8">
        <div class="md:w-4/6 2xl:w-4/6 m-auto">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-2 md:col-span-1 flex flex-col">
                    <div class="flex justify-between mb-3">
                        <div class="mr-5">
                            <a v-if="setting" href="/" class="flex ml-2 md:mr-24">
                                <img :src="assetBaseUrl+setting.software_logo_black" alt="" class="h-10 mr-3 block dark:hidden " />
                                <img :src="assetBaseUrl+setting.software_logo_white" alt=""  class="h-10 mr-3 hidden dark:block" />
                            </a>
                        </div>
                        <div>
                            <img :src="`/assets/images/+18.png`" width="42" alt=""/>
                        </div>
                    </div>
                    <div v-if="setting">
                        <div class="">
                            {{ setting.software_description }}
                        </div>

                        <div v-if="custom" class="flex  mt-5">
                            <div v-if="custom.instagram" class="flex pl-2">
                                <a :href="custom.instagram" target="_blank" class="text-3xl text-gray-500 p-3">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                            </div>
                            <div v-if="custom.telegram" class="flex pl-2">
                                <a :href="custom.telegram" target="_blank" class="text-3xl text-gray-500 p-3">
                                    <i class="fa-brands fa-telegram"></i>
                                </a>
                            </div>
                            <div v-if="custom.whatsapp" class="flex pl-2">
                                <a :href="custom.whatsapp" target="_blank" class="text-3xl text-gray-500 p-3">
                                    <i class="fa-brands fa-whatsapp"></i>
                                </a>
                            </div>
                            <div v-if="custom.facebook" class="flex pl-2">
                                <a :href="custom.facebook" target="_blank" class="text-3xl text-gray-500 p-3">
                                    <i class="fa-brands fa-facebook-f"></i>
                                </a>
                            </div>
                            <div v-if="custom.youtube" class="flex pl-2">
                                <a :href="custom.youtube" target="_blank" class="text-3xl text-gray-500 p-3">
                                    <i class="fa-brands fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-list">
                    <h3 class="text-base md:text-lg text-gray-600 dark:text-white font-bold">{{ $t('USEFUL LINKS') }}</h3>
                    <ul class="list-none mt-5">
                        <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]"><a href="">{{ $t('Payment methods') }}</a></li>
                    </ul>
                </div>
                <div class="footer-list">
                    <h3 class="text-base md:text-lg text-gray-600 dark:text-white font-bold">{{ $t('ABOUT US') }}</h3>
                    <ul class="list-none mt-5">
                        <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]"><a @click="$router.push('/terms/service')" href="">{{ $t('Service Terms') }}</a></li>
                        <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]"><a @click="$router.push('/terms/privacy-policy')" href="">{{ $t('Privacy Policy') }}</a></li>
                        <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]"><a @click="$router.push('/terms/bonus')" href="">{{ $t('Bonus Terms') }}</a></li>
                        <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]"><a @click="$router.push('/terms/bonus-welcome')" href="">{{ $t('Welcome Bonus') }}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <hr class="border-t border-gray-200 dark:border-gray-600 mt-5">

        <div v-if="setting" class="mt-5 flex flex-col justify-start ">
            <p class="text-[12px] w-full">© {{ year }} {{ setting.software_name }} {{ $t('All rights reserved') }}.</p>
        </div>
    </div>
</template>

<script>
    import HttpApi from "@/Services/HttpApi.js";
    import { useSettingStore } from "@/Stores/SettingStore.js";

    export default {
        props: [],
        components: {},
        data() {
            return {
                isLoading: false,
                year: new Date().getFullYear(),
                setting: null,
                custom: null,
            };
        },
        setup(props) {
            return {};
        },
        computed: {
            assetBaseUrl() {
                const baseUrl = import.meta.env.VITE_ASSET_BASE_URL || '';
                return baseUrl.endsWith('/') ? baseUrl : baseUrl + '/';
            },
        },
        mounted() {
            this.getSetting();
            this.getCustomSettings(); // Chama o método para preencher os dados de custom
        },
        methods: {
            getSetting() {
                const settingStore = useSettingStore();
                const settingData = settingStore.setting;
                if (settingData) {
                    this.setting = settingData;
                }
            },
            getCustomSettings() {
                const settingStore = useSettingStore();
                this.custom = settingStore.custom || {}; // Ajuste conforme necessário
            },
        },
        created() {
            this.getSetting();
            this.getCustomSettings(); // Garantir que custom é preenchido
        },
        watch: {},
    };
</script>

<style scoped>

</style>
